import { computed, type ComputedRef } from 'vue';
import { Price } from '~/helpers/Price';
import type { AhCartItem, PromotionItemComposition } from '~/types/models/cart';
import type { Schemas } from '#shopware';

export function useAhCart() {
    const { cartItems } = useCart();

    // get compositions from promotion lineItems and combine them into single object
    const _calculateDiscountComposition = (): PromotionItemComposition[] => {
        const mergedCompositions: PromotionItemComposition[] = [];
        let discountSum = 0;
        let discountSumWithRoundedItems = 0;

        (cartItems as ComputedRef<AhCartItem[]>).value.forEach((item: AhCartItem) => {
            if (item.type === 'promotion' && item.payload.composition && item.payload.composition.length > 0) {
                _mergeComposition(item.payload.composition, mergedCompositions);
                const { discountSum: sum, discountSumWithRoundedItems: roundedSum } = _calculateDiscountSums(
                    item.payload.composition,
                );
                discountSum += sum;
                discountSumWithRoundedItems += roundedSum;
            }
        });

        _roundDiscounts(mergedCompositions);

        const discountSumRounded = Price.round2Decimals(discountSum);
        // round difference to avoid js calc bugs with floating number, e.g 5.68 - 5.67 = 0.009999999999999787
        const priceDifference = Price.round2Decimals(discountSumRounded - discountSumWithRoundedItems);

        _applyRoundingDifference(mergedCompositions, priceDifference);

        return mergedCompositions;
    };

    const _mergeComposition = (
        composition: PromotionItemComposition[],
        mergedCompositions: PromotionItemComposition[],
    ) => {
        composition.forEach((composition) => {
            const existingItemIndex = mergedCompositions.findIndex((mergedItem) => mergedItem.id === composition.id);
            if (existingItemIndex !== -1) {
                mergedCompositions[existingItemIndex].quantity = composition.quantity;
                mergedCompositions[existingItemIndex].discount += composition.discount;
            } else {
                mergedCompositions.push({ ...composition });
            }
        });
    };

    const _calculateDiscountSums = (composition: PromotionItemComposition[]) => {
        let discountSum = 0;
        let discountSumWithRoundedItems = 0;

        composition.forEach((composition) => {
            discountSum += composition.discount;
            discountSumWithRoundedItems += Price.round2Decimals(composition.discount);
        });

        return { discountSum, discountSumWithRoundedItems };
    };

    const _roundDiscounts = (mergedCompositions: PromotionItemComposition[]) => {
        mergedCompositions.forEach((composition) => {
            composition.discount = Price.round2Decimals(composition.discount);
        });
    };

    const _applyRoundingDifference = (mergedCompositions: PromotionItemComposition[], priceDifference: number) => {
        if (priceDifference >= 0.01) {
            mergedCompositions[0].discount += priceDifference;
        }
    };

    const discountComposition: ComputedRef<PromotionItemComposition[]> = computed(() => {
        return _calculateDiscountComposition();
    });

    // get composition part for given lineItem
    const getItemDiscountComposition = (lineItem: Schemas['LineItem']) => {
        if (lineItem.id && lineItem.type === 'product') {
            const itemDiscountComposition = discountComposition.value.find(
                (composition) => composition.id === lineItem.id,
            );
            return itemDiscountComposition ? itemDiscountComposition : null;
        }

        return null;
    };

    const getCartItemById = (cartItemId: string): Schemas['LineItem'] | undefined => {
        return cartItems.value.find(({ id }) => id === cartItemId);
    };

    return {
        discountComposition,
        getItemDiscountComposition,
        getCartItemById,
    };
}
